var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "page-i18n" } },
    [
      _c(
        "vx-card",
        { attrs: { title: "Change Locale" } },
        [
          _c("span", [
            _vm._v("Your Current Locale: "),
            _c("strong", [_vm._v(_vm._s(this.$i18n.locale))]),
          ]),
          _c(
            "div",
            { staticClass: "flex flex-col" },
            [
              _c(
                "vs-radio",
                {
                  staticClass: "mt-3",
                  attrs: { "vs-value": "en" },
                  model: {
                    value: _vm.locale,
                    callback: function ($$v) {
                      _vm.locale = $$v
                    },
                    expression: "locale",
                  },
                },
                [_c("span", { staticClass: "mr-2" }, [_vm._v("English")])]
              ),
              _c(
                "vs-radio",
                {
                  staticClass: "mt-3",
                  attrs: { "vs-value": "fr" },
                  model: {
                    value: _vm.locale,
                    callback: function ($$v) {
                      _vm.locale = $$v
                    },
                    expression: "locale",
                  },
                },
                [_c("span", { staticClass: "mr-2" }, [_vm._v("French")])]
              ),
              _c(
                "vs-radio",
                {
                  staticClass: "mt-3",
                  attrs: { "vs-value": "de" },
                  model: {
                    value: _vm.locale,
                    callback: function ($$v) {
                      _vm.locale = $$v
                    },
                    expression: "locale",
                  },
                },
                [_c("span", { staticClass: "mr-2" }, [_vm._v("German")])]
              ),
              _c(
                "vs-radio",
                {
                  staticClass: "mt-3",
                  attrs: { "vs-value": "pt" },
                  model: {
                    value: _vm.locale,
                    callback: function ($$v) {
                      _vm.locale = $$v
                    },
                    expression: "locale",
                  },
                },
                [_c("span", { staticClass: "mr-2" }, [_vm._v("Portuguese")])]
              ),
            ],
            1
          ),
          _c(
            "vx-card",
            {
              staticClass: "mt-base",
              attrs: {
                title: _vm.$t("demoTitle"),
                "no-shadow": "",
                "card-border": "",
              },
            },
            [_c("p", [_vm._v(_vm._s(_vm.$t("demoText")))])]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }